<script setup lang="ts">
import {LOCALE_DIALOG_DISPLAY_REQUESTED} from "~/types/bus-event-names"
const {locale, locales} = useI18n()

const props = defineProps<{
  verboseLabel?: boolean
}>()

const label = computed(() => {
  if (props.verboseLabel) {
    return locales.value.find((loopLocale: any) => loopLocale.code === locale.value)?.name
  }

  return locale.value.toUpperCase()
})
</script>

<template>
  <AtomsButton :label="label" hover-scheme="none" dark-color="domain-dark" class="text-center" after-icon="expand_more" tag="button" @click="$mitt.emit(LOCALE_DIALOG_DISPLAY_REQUESTED)" />
</template>