<script setup lang="ts">
import {LOCALE_DIALOG_DISPLAY_REQUESTED} from "~/types/bus-event-names"
import {useCurrentPageRouteI18nStore} from "~/stores/current-page-route-i18n"
import Dialog from "~/components/molecules/Dialog.vue"

const {locale, locales} = useI18n()
const {getCurrentLocalPageObject} = useCurrentPageRouteI18nStore()
const dialog = ref<typeof Dialog | null>(null)

const listedLocales = computed(() => {
    return locales.value.filter((locale: any) => !locale.unlisted)
})

useBusEvent(LOCALE_DIALOG_DISPLAY_REQUESTED, openDialog)

function openDialog() {
    dialog.value?.show()
}

</script>

<template>
    <MoleculesDialog ref="dialog">
        <div class="max-w-sm">
            <AtomsButton class="w-full mb-bento"
                         v-for="loopLocale in listedLocales"
                         :key="loopLocale.code"
                         :href="localePath(getCurrentLocalPageObject(loopLocale.code), loopLocale.code)"
                         hover-scheme="none" hover-text-color="domain"
                         :before-icon="loopLocale.code === locale ? 'done' : null"
                         before-icon-class="text-domain"
                         :class="{'pointer-events-none': loopLocale.code === locale}"
                         :label="loopLocale.name"/>
        </div>
    </MoleculesDialog>
</template>