<script setup lang="ts">
import {DOMAIN_DIALOG_DISPLAY_REQUESTED} from "~/types/bus-event-names"
import Dialog from "~/components/molecules/Dialog.vue"
import {useDomainDataStore} from "~/stores/domain"
import {getCegepFlagHtml} from '~/composables/useCegepFlag'

const domainDataStore = useDomainDataStore()
const {locale} = useI18n()
const dialog = ref<typeof Dialog | null>(null)

useBusEvent(DOMAIN_DIALOG_DISPLAY_REQUESTED, openDialog)

function openDialog() {
  dialog.value?.show()
}

const wwwLabel = computed(() => {
  let label = domainDataStore.getDomainLabel('www')

  const cegepFlagHtml = getCegepFlagHtml()
  if (cegepFlagHtml) {
    label += ` ${cegepFlagHtml}`
  }

  return label
})

</script>

<template>
  <MoleculesDialog ref="dialog">
    <AtomsButton class="w-full mb-bento"
                 :label="domainDataStore.getDomainLabel('junior')"
                 before-icon-class="text-domain"
                 :class="{'pointer-events-none': domainDataStore.isPrimary}"
                 :before-icon="domainDataStore.isPrimary ? 'done' : null"
                 :href="domainDataStore.getDomainHome('junior', locale)" hover-scheme="none" hover-text-color="domain"/>
    <AtomsButton class="w-full mb-bento"
                 before-icon-class="text-domain"
                 :class="{'pointer-events-none': domainDataStore.isSecondary}"
                 :before-icon="domainDataStore.isSecondary ? 'done' : null"
                 :label="wwwLabel" allow-html-label :href="domainDataStore.getDomainHome('www', locale)"
                 hover-scheme="none" hover-text-color="domain"/>
  </MoleculesDialog>
</template>